import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IFilters, IFiltersDataClient, IOperationsRequestsFilters } from '../../models/lm-clients.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OperationService } from 'src/app/pages/lm-transfers/services/operation.service';
import { ImagesDialogComponent } from 'src/app/pages/lm-transfers/sections/images-dialog/images-dialog.component';
import { CouponVersion, IOperationContent } from 'src/app/pages/lm-transfers/models/lm-transfers.model';
import { CouponDialogComponent } from 'src/app/pages/lm-transfers/sections/coupon-dialog/coupon-dialog.component';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lm-client-operations',
  templateUrl: './lm-client-operations.component.html',
  styleUrls: ['./lm-client-operations.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmClientOperationsComponent implements OnInit, OnDestroy {

  _translationSubscription: Subscription;
  @Input() filtersData: IFiltersDataClient;

  _messages: { [key: string]: string };
  filterType: string = "clientOperations";
  filters: IFilters = {};
  content: IOperationContent[] = [];
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  stringShowMore: string;
  buttonShowMore: boolean;
  operationsRequestsFilters: IOperationsRequestsFilters;

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  displayedColumns: string[] = ['id', 'operationStatus', 'operationOfferedServices', 'clientDetails', 'startTime', 'endTime', 'professionalDetails', 'totalCost',
    'totalPaid', 'operationAddress', 'products', 'images', 'unit', 'rejectionMessage', 'campaigns', 'storeName', 'comercialId', 'fill'];

  constructor(private _operationService: OperationService,
    private _translateService: TranslateService,
    public dialog: MatDialog,
    private _transferService: TransferService) {
    this._translationSubscription = this._translateService
      .get('lm-client.lm-client-operations.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
    this.stringShowMore = this._messages['see_all'];
  }

  ngOnInit() {
    if (this.filters !== null && this.filters.isFilterBuilt == true) {
      this.getOperations();
    }
  }

  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  /**
   * Retrieve operation with filter
   */
  getOperations() {
    this.operationsRequestsFilters = this.filters.operationsRequestsFilters;
    this.loading = true;
    this.content = [];
    this.totalElements = 0;
    this._operationService.getOperations(this.page, this.pageSize, this.operationsRequestsFilters).subscribe({
      next: this.handleResponse.bind(this),
      error: this.handleError.bind(this)
    }).add(() => {
      this.loading = false;
    });
  }

  handleResponse(response: any): void {
    if (response.data.totalElements === 0) {
      alert(this._messages['no_result_found']);
      this.content = [];
    } else {
      this.content = response.data.content;
      this.totalElements = response.data.totalElements;
    }
  }

  handleError(error: any): void {
    alert(this._messages['no_result_found']);
    this.content = [];
  }

  getOperationsFilters() {
    this.getOperations();
  }

  getFiltersToOperations(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getOperations();
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getOperations();
  }


  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime()
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString()
    }
    return d
  }

  isEmpty(str) {
    return (!str || str.length === 0);
  }

  showMore() {
    this.buttonShowMore ? this.stringShowMore = this._messages['see_all'] : this.stringShowMore = this._messages['hide_all'];
    this.buttonShowMore = !this.buttonShowMore;
  }

  getSum(list): number {
    let priceOriginal = 0;
    let price = 0;
    for (let i = 0, len = list.length; i < len; i++) {
      const item = list[i];
      priceOriginal += item.priceOriginal;
      price += item.price;
    }
    return priceOriginal - price;
  }


  /**
   * Show modal with operation images
   * @param url URL Image
   * @param type Image Type
   */
  openImagesDialog(url: string, type: string): void {
    if (url != null) {
      var dialogRef = this.dialog.open(ImagesDialogComponent, {
        width: '80%',
        height: '80%',
        maxHeight: '80%',
        data: {
          url: url,
          type: type
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
      });
    }
  }

  /**
   * Show modal with coupon information
   * @param couponInfo Coupon Info
   */
  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

}
