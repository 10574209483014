import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TransferService } from 'src/app/pages/lm-transfers/services/transfers.service';
import { ICoverageData, IFilters, IFiltersData, IServicesFilters } from '../../models/lm-professional.model';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/pages/modals/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Group, LmOfferedService, LmOfferedServiceChildren, LmOfferedServicesForSort } from '../../models/lm-offered-services.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lm-professional-services',
  templateUrl: './lm-professional-services.component.html',
  styleUrls: ['./lm-professional-services.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmProfessionalServicesComponent implements OnInit, OnDestroy {
  _translateSubscription: Subscription;
  allData: any[] = [];
  showSortSection: boolean = false;
  loading: boolean = false;
  filterType: string = "professionalServices";
  filters: IFilters = {};
  servicesFilters: IServicesFilters;
  services: LmOfferedService[] = [];
  servicesToSort: LmOfferedServicesForSort[] = [];
  columns: any[];
  dictionary: { [key: string]: string };

  public dataSource = new MatTableDataSource<any | Group>([]);
  displayedColumns: string[];
  _allGroup: Group[];
  groupByColumns: string[] = [];

  expandedCar: any[] = [];
  expandedSubCar: LmOfferedServiceChildren[] = [];

  @Input() filtersData: IFiltersData;
  coverageData: ICoverageData;
  showServiceCoverage: boolean = false;
  dialogRef: MatDialogRef<ConfirmDialogComponent>;


  constructor(private _transferService: TransferService, public dialog: MatDialog, private _translateService: TranslateService, private _snackBar: MatSnackBar) {

    this.columns = [{
      field: 'code'
    }, {
      field: 'description'
    }, {
      field: 'typeName'
    }, {
      field: 'is_active'
    }, {
      field: 'valuations'
    }, {
      field: 'no_comments'
    }, {
      field: 'actions'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['parentName'];

    this._translateSubscription = this._translateService.get('lm-professional.lm-professional-services.services-types').subscribe(res => {
      this.dictionary = res;
    });
  }
  ngOnDestroy(): void {
    this._translateSubscription.unsubscribe();
  }

  ngOnInit() {
    console.debug('aqkdj');
    if (this.filters !== null && this.filters.isFilterBuilt == true) {
      this.getServices();
    }
  }

  /**
   * Retrieve all services from professional
   */
  getServices() {
    const messageError = this._translateService.instant('lm-professional.lm-professional-services.messageError');
    this.servicesFilters = this.filters.servicesFilters;
    this.loading = true;
    this._transferService.getServices(this.filtersData.professionalId, this.servicesFilters).subscribe(response => {
      //this.flattenServiceInformation(response.data);
      this.services = response.data;
      let result: any[] = [];
      let service: LmOfferedService;
      this.services.forEach(item => {
        service = item;
        if (service.offeredServiceChildren) {
          service.offeredServiceChildren.forEach(child => {
            child.typeName = this.retrieveTypeService(child.type);
            if (child.rating) {
              child.valuations = child.rating.averageGeneralRating;
              child.no_comments = child.rating.totalAverage;
            }
            result.push(child)
          })
        }
      });
      let parents = [];
      this.services.forEach(item => {
        if (service.level <= 3) {
          item['nivel'] = 1;
          item['expanded'] = false;
          item['totalCounts'] = item.offeredServiceChildren.length
          parents.push(item);
        }
      });

      this._allGroup = this.getGroups(parents);

      this.allData = result;
      this.loading = false
      this.dataSource.data = this._allGroup;
      this.showSortSection = false;
    });

  }

  retrieveTypeService(type: number): string {
    let typeText: string;
    typeText = this.dictionary[type];
    return typeText;
  }

  /**
   * Obtains groups
   * @param parents 
   * @returns 
   */
  getGroups(parents: any[]): any[] {
    return this.uniqueBy(
      parents.map(row => {
        const result = new Group();
        result.id = row['id'];
        result.description = row['description'];
        result.expanded = row['expanded'];
        result.landingPage = row['landingPage'] ? row['landingPage'] : '';
        result.nivel = row['nivel'];
        result.order = row['order'];
        result.code = row['code'] ? row['code'] : '';
        result.parentCode = row['parentCode'] ? row['parentCode'] : '';
        result.parentName = row['description'];
        result.totalCounts = row['totalCounts'];
        result.is_active = row['is_active'] ? 'SI' : 'NO';
        result.type = this.retrieveTypeService(row['type']);
        return result;
      }), JSON.stringify);
  }

  /**
   * Validate that id is unique
   * @param a 
   * @param key 
   * @returns 
   */
  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  /**
   * Add new group
   * @param allGroup 
   * @param data 
   * @param groupByColumns 
   * @param dataRow 
   * @returns 
   */
  addGroupsNew(allGroup: any[], data: any[], groupByColumns: string[], dataRow: any): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSubnivelNew(allGroup, data, 0, groupByColumns, rootGroup, dataRow);
  }

  /**
   * Obtain sublevel (child service)
   * @param allGroup 
   * @param data 
   * @param nivel 
   * @param groupByColumns 
   * @param parent 
   * @param dataRow 
   * @returns 
   */
  getSubnivelNew(allGroup: any[], data: any[], nivel: number, groupByColumns: string[], parent: Group, dataRow: any): any[] {
    if (nivel >= groupByColumns.length) {
      return data;
    }
    const currentColumn = groupByColumns[nivel];
    let subGroups = [];
    allGroup.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;

      if (group.parentName == dataRow.parentName.toString()) {
        group.expanded = dataRow.expanded;
        const subGroup = this.getSubnivelNew(allGroup, rowsInGroup, nivel + 1, groupByColumns, group, dataRow.parentName.toString());
        this.expandedSubCar = subGroup;
        subGroup.unshift(group);
        subGroups = subGroups.concat(subGroup);
      } else {
        subGroups = subGroups.concat(group);
      }
    });
    return subGroups;
  }

  /**
   * Parent services is Group
   * @param index 
   * @param item 
   * @returns 
   */
  isGroup(index, item): boolean {
    return item.nivel;
  }

  /**
   * Expand/Collapse table with services
   * @param row 
   */
  groupHeaderClick(row) {
    if (row.expanded) {
      row.expanded = false;
      this.dataSource.data = this._allGroup;
    } else {
      row.expanded = true;
      this.expandedCar = row;
      this.dataSource.data = this.addGroupsNew(
        this._allGroup,
        this.allData,
        this.groupByColumns,
        row
      );
    }
  }

  /**
   * Filter services
   */
  getServicesFilters() {
    this.getServices();
  }

  /**
   * Filter to be applied
   * @param filtersApply    - Filter params
   */
  getFiltersToServices(filtersApply) {
    this.filters = filtersApply;
    this.getServices();
  }

  /**
   * Get offered service coverage
   * @param offeredService    - ID of the offered service
   */
  getCoverageData(offeredService) {
    this.showServiceCoverage = true;
    this.coverageData = {
      professionalId: this.filtersData.professionalId,
      offeredServiceId: offeredService.id,
      landingPage: offeredService.landingPage
    }

  }

  /**
   * Open dialog for the confirmation
   * @param payload     - Information for the dialog
   */
  confirmDialogDelete(payload: any) {
    let dialogMessage = this._translateService.instant('lm-professional.lm-professional-services.changeStatus.active');
    if (payload['statusService'] === 'INACTIVE') {
      dialogMessage = this._translateService.instant('lm-professional.lm-professional-services.changeStatus.inactive');
    }
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      disableClose: true,
      data: {
        value: dialogMessage
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        this.changeStatusOfferedService(payload['offeredServiceId'], payload['statusService']);
      } else {
        this.dialogRef.close();
      }
    });
  }

  /**
   * Activate offered service
   * @param offeredService  - The offered service
   */
  activateOfferedService(offeredService): void {
    this.confirmDialogDelete({ 'offeredServiceId': offeredService['id'], 'statusService': 'ACTIVE' });
  }

  /**
   * Inactivate offered service
   * @param offeredService  - The offered service
   */
  desactivateOfferedService(offeredService): void {
    this.confirmDialogDelete({ 'offeredServiceId': offeredService['id'], 'statusService': 'INACTIVE' });
  }

  /**
   * Call function to change offered service status
   * @param offeredServiceId    - ID of the offered service
   * @param statusService       - Status change
   */
  changeStatusOfferedService(offeredServiceId: number, statusService: string): void {
    this._transferService.changeStatusOfferedService(this.filtersData.professionalId, offeredServiceId, statusService).subscribe({
      next: (res) => {
        if (res['code'] === "200") {
          this.dialogRef.close();
          this.getServices()
          this._snackBar.open(this._translateService.instant('lm-professional.lm-professional-services.changeStatus.success'),
            this._translateService.instant('lm-professional.lm-professional-services.changeStatus.ok'), { duration: 1500 });
        }
      },
      error: () => {
        this._snackBar.open(this._translateService.instant('lm-professional.lm-professional-services.changeStatus.error'),
          this._translateService.instant('lm-professional.lm-professional-services.changeStatus.ok'), { duration: 1500 });
      }
    });
  }

}





