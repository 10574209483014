import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { RequestService } from 'src/app/pages/lm-transfers/services/request.service';
import { IRequestStatusContent, IRequestStatusData } from '../../models/lm-journey.model';

@Component({
  selector: 'app-lm-journey-timeline',
  templateUrl: './lm-journey-timeline.component.html',
  styleUrls: ['./lm-journey-timeline.component.scss', '../../../../pages/utils/mat-table.scss']
})
export class LmJourneyTimelineComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: IRequestStatusData;
  content: IRequestStatusContent[] = []
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  displayedColumns: string[] = ['type', 'initialStatus', 'endStatus', 'statusStartDate', 'statusEndDate', 'responsible', 'comments'];
  @Input() requestId: any;

  constructor(private _requestService:  RequestService, private _translateService: TranslateService) {
    this._translationSubscription = this._translateService
      .get('lm-journey-details.sections.lm-journey-timeline.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
      });
   }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }
  ngOnInit() {
    this.getTimelineData(this.requestId);
  }


  getTimelineData(requestId: any) {
    this.loading = true;
    this.content = []
    this.totalElements = 0;
    this._requestService.getTimelineData(this.page, this.pageSize,this.requestId).then(response => {
      this.data = response.data;
      if (this.data.totalElements === 0) {
        alert(this._messages['no_result_found']);
        this.loading = false;
        this.content = []
      } else {
        this.loading = false;
        this.content = this.data.content;
        this.totalElements = this.data.totalElements
      }
    },
      err => {
        console.error(err);
        alert(this._messages['no_result_found']);
        this.loading = false;
        this.content = []
      });
  }
  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getTimelineData(this.requestId);
  }

}
