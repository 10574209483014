<div style="display:inline !important;">
    <app-lm-filters-client-details
    [filterType]="filterType"
    [filtersData]="filtersData"
    [loading]="loading" (filtersApply)="getFiltersToRequests($event)">
    </app-lm-filters-client-details>
</div>

<div class="content">
    <div class="example-container ">
        <mat-table [dataSource]="content">
            <ng-container matColumnDef="id" sticky>
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.id' | translate}}</th>
              <nav>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/work-details', element.id]"><strong>Sol: {{element.id}}</strong><br /></a>
                </td>
              </nav>
            </ng-container>
      
            <ng-container matColumnDef="requestStatus" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.status' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div style="border-radius: 5px; padding: 5px;" 
                [ngStyle]="{'color': element.requestStatusRest.property.text_color, 
                'background': element.requestStatusRest.property.background_color + '20', 
                'border': '1px solid' + element.requestStatusRest.property.background_color}">
                  {{element.requestStatusRest.description}}
                </div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="offeredService" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.services' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div><span class="service_code">{{element.offeredService?.code}}</span> : <span
                    class="service_name">{{element.offeredService?.title}}</span></div>
                <div class="service_price" *ngIf="element?.offeredService?.price" >{{'lm-client.lm-client-requests.services.price' | translate}} 
                  <span style="text-decoration: line-through;" *ngIf="element?.requestCoupons?.length > 0">
                    {{element.offeredService?.priceOriginal}} {{element.offeredService?.currency}}
                  </span> 
                  <span>{{element.offeredService?.price}} {{element.offeredService?.currency}}</span>
                  
                  <span *ngIf="element.offeredService?.profitMarginDiscount != null" > ({{'lm-transfers.operations.services.margin' | translate}} {{element.offeredService?.profitMarginDiscount}} %)</span>
                  <span *ngIf="!element?.offeredService?.profitMargin != null && element?.offeredService?.profitMarginDiscount == null"> ({{'lm-transfers.operations.services.margin' | translate}} {{element.offeredService?.profitMargin}} %)</span>
                
                  <div class="service_tax">{{'lm-client.lm-client-requests.services.iva' | translate}} {{element.offeredService?.taxDetails?.taxType}}
                    {{element.offeredService?.taxDetails?.taxRate}}</div>
                  <div class="service_duration">{{'lm-client.lm-client-requests.services.duration' | translate}} {{element.offeredService?.duration}}</div>
                  <div *ngIf="element?.requestCoupons?.length > 0" class="service-coupon">{{'lm-transfers.requests.services.discount' | translate}} 
                    (<a class="coupon-code-link" (click)="showCouponVersion(element.requestCoupons[0])">{{element.requestCoupons[0].couponCode}}</a>) -{{element.offeredService?.priceOriginal - element.offeredService?.price | number:'1.0-2'}} {{element.offeredService?.currency}}
                  </div>
                </div>
                <div *ngIf="!element?.offeredService?.price" >
                  <span class="not_available">{{'lm-client.lm-client-requests.services.price_not_available' | translate}}</span>
                </div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="clientDetails" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.client' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div class="client_name" *ngIf="element.clientDetails">{{element.clientDetails?.name}}
                  {{element.clientDetails?.lastName}}</div>
                <div class="client_email" *ngIf="element.clientDetails">{{element.clientDetails?.email}}</div>
                <div *ngIf="element.clientDetails">{{'lm-client.lm-client-requests.client.tel' | translate}}
                  <span *ngFor="let p of element.clientDetails.phones">
                    <span>{{p?.number}} </span>
                  </span>
                </div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="createDate" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.date_created' | translate}}</th>
              <td mat-cell *matCellDef="let element"> {{element.createDate | date:'fullDate': undefined :
                'en-EN'}},{{element.createDate | date:'shortTime'}} </td>
            </ng-container>
      
            <ng-container matColumnDef="operationDate" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.appointement_date' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.operationDate">{{element.operationDate | date:'fullDate': undefined :
                  'en-EN'}},{{element.operationDate | date:'shortTime'}}</span>
                <span *ngIf="!element.operationDate">-</span>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="endDate" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.final_date' | translate}}</th>
              <td mat-cell *matCellDef="let element"> {{element.endDate | date:'fullDate': undefined :
                'en-EN'}},{{element.endDate | date:'shortTime'}} </td>
            </ng-container>
      
      
            <ng-container matColumnDef="professionalDetails" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.professional' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div>
                  <span class="professional_name">{{element.professionalDetails?.name}}
                    {{element.professionalDetails?.lastName}}</span>
                  <span *ngIf="element.professionalDetails?.available" matTooltipPosition="right" matTooltip="{{'lm-client.lm-client-requests.client.active' | translate}}">
                    <i class="material-icons">visibility</i>
                  </span>
                  <span *ngIf="!element.professionalDetails?.available" matTooltipPosition="right" matTooltip="{{'lm-client.lm-client-requests.client.inactive' | translate}}">
                    <i class="material-icons">visibility_off</i>
                  </span>
                </div>
                <div class="professional_email">{{element.professionalDetails?.email}}</div>
                <div class="professional_document">{{element.professionalDetails?.document}}</div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="requestAddress" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.address_serevices' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div>{{element.requestAddress?.address}}</div>
                <div>{{element.requestAddress?.complementAddress}}</div>
                <div>{{element.requestAddress?.zipCode}}</div>
                <div *ngIf="element.requestAddress?.city">{{element.requestAddress?.city}}</div>
                <div *ngIf="element.requestAddress?.province">{{element.requestAddress?.province}}</div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="campaigns" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.campaign' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.listRequestCampaigns.length > 0">
                  <div *ngFor="let p of element.listRequestCampaigns">
                    <p>{{p?.campaignCode}}</p>
                  </div>
                </div>
                <div *ngIf="element.listRequestCampaigns.length <= 0">
                  <p>WEB</p>
                </div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="storeName" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.shop' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.storeRest">{{element.storeRest?.name}} ({{element.storeRest?.leroyId}})</div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="comercialId" class="spacing">
              <th mat-header-cell *matHeaderCellDef>{{'lm-client.lm-client-requests.cells.commercial_id' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <div>{{element.commercialId}}</div>
              </td>
            </ng-container>
      
            <ng-container matColumnDef="fill">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef></td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>