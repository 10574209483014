<div style="display:inline !important;">
    <app-lm-filters-professional-details 
    [filterType]="filterType"
    [filtersData]="filtersData"
    [loading]="loading"
    (filtersApply)="getFiltersToOperations($event)">
    </app-lm-filters-professional-details>
</div>


<div class="content">
    <div class="example-container ">
        <mat-table mat-table [dataSource]='content' class="mat-elevation-z8">
            <ng-container matColumnDef="id" sticky>
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.id' | translate}}</th>
                    <nav>
                        <td mat-cell *matCellDef="let element">
                            <a [routerLink]="['/work-details', element.requestId]"><strong>Op: {{element.id}}</strong><br /></a>Sol: {{element.requestId}}
                        </td>
                    </nav>
            </ng-container>

            <ng-container matColumnDef="operationStatus" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.status' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div style="border-radius: 5px; padding: 5px;" 
                    [ngStyle]="{'color': element.operationStatus.propertyStatus.text_color, 
                    'background': element.operationStatus.propertyStatus.background_color + '20', 
                    'border': '1px solid' + element.operationStatus.propertyStatus.background_color}">
                        {{element.operationStatus?.description}} </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="operationOfferedServices" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.services' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let p of element.operationOfferedServices ; first as isFirst">
                        <span *ngIf="isFirst">
                            <div><span class="service_code">{{p?.code}}</span> : <span
                                    class="service_name">{{p?.title}}</span></div>
                            <div class="service_price">{{'lm-professional.lm-professional-operations.services.price' | translate}} 
                                <span style="text-decoration: line-through;" *ngIf="element?.operationCoupons?.length > 0">
                                    {{p?.priceOriginal}} {{p?.currency}}
                                </span> 
                                <span> {{p?.price}}  {{p?.currency}}</span>

                                <span *ngIf="p?.profitMarginDiscount != null" > ({{'lm-transfers.operations.services.margin' | translate}} {{p?.profitMarginDiscount}} %)</span>
                                <span *ngIf="!p?.profitMargin != null && p?.profitMarginDiscount == null"> ({{'lm-transfers.operations.services.margin' | translate}} {{p?.profitMargin}} %)</span>
                            </div>
                            <div class="service_tax">{{p?.taxDetails?.taxRate}} {{p?.taxDetails?.taxType}}</div>
                            <div class="service_date">{{'lm-professional.lm-professional-operations.services.added_on' | translate}} {{p?.createDate}}</div>
                            <div *ngIf="element?.operationCoupons?.length > 0" class="service-coupon">{{'lm-transfers.operations.services.discount' | translate}} 
                                (<a class="coupon-code-link" (click)="showCouponVersion(element.operationCoupons[0])">{{element.operationCoupons[0].couponCode}}</a>)
                                {{ - getSum(element.operationOfferedServices) | number : '1.2-2' }}
                            </div>
                        </span>
                    </div>
                    <div *ngIf="element.operationOfferedServices?.length > 1">
                        <button mat-stroked-button (click)="showMore()">
                            <div class="expand_div"><label>{{stringShowMore}}</label>
                                <mat-icon class="mat-18" *ngIf="!buttonShowMore">expand_more</mat-icon>
                                <mat-icon class="mat-18" *ngIf="buttonShowMore">expand_less</mat-icon>
                            </div>
                        </button>
                        <div *ngIf="buttonShowMore">
                            <div *ngFor="let p of element.operationOfferedServices | slice:1; let i = index;"
                                class="upperline">
                                <div><span class="service_code">{{p['code']}}</span> : <span
                                        class="service_name">{{p['title']}}</span></div>
                                <div class="service_price">{{'lm-professional.lm-professional-operations.services.price' | translate}} {{p['price']}} {{p['currency']}} ({{'lm-professional.lm-professional-operations.services.margin' | translate}}
                                    {{p['profitMargin']}} %)</div>
                                <div class="service_tax">{{p['taxDetails']['taxRate']}} {{p['taxDetails']['taxType']}}</div>
                                <div class="service_date">{{'lm-professional.lm-professional-operations.services.added_on' | translate}} {{p['createDate']}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="clientDetails" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.client' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div class="client_name" *ngIf="element.clientDetails">{{element.clientDetails?.name}}
                        {{element.clientDetails?.lastName}}</div>
                    <div class="client_email" *ngIf="element.clientDetails">{{element.clientDetails?.email}}</div>
                    <div *ngIf="element.clientDetails">{{'lm-professional.lm-professional-operations.client.tel' | translate}}
                        <span *ngFor="let p of element.clientDetails.phones">
                            <span>{{p?.number}} </span>
                        </span>
                    </div>
                    <div>{{'lm-professional.lm-professional-operations.client.billing_data' | translate}}</div>
                    <div>{{'lm-professional.lm-professional-operations.client.docs' | translate}} {{element.billingData?.documentNumber}}</div>
                    <div>{{'lm-professional.lm-professional-operations.client.name' | translate}} {{element.billingData?.name}} {{element.billingData?.lastName}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="startTime" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.appointment_date' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.startTime | date:'fullDate'}},{{element.startTime |
                    date:'shortTime'}} </td>
            </ng-container>

            <ng-container matColumnDef="endTime" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.final_date' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.endTime | date:'fullDate'}},{{element.endTime |
                    date:'shortTime'}} </td>
            </ng-container>

            <ng-container matColumnDef="professionalDetails" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.profesional' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <span class="professional_name">{{element.professionalDetails?.name}}
                            {{element.professionalDetails?.lastName}}</span>
                        <span *ngIf="element.professionalDetails?.available" matTooltipPosition="right"
                            matTooltip="{{'lm-professional.lm-professional-operations.profesional.active' | translate}}">
                            <i class="material-icons">visibility</i>
                        </span>
                        <span *ngIf="!element.professionalDetails?.available" matTooltipPosition="right"
                            matTooltip="{{'lm-professional.lm-professional-operations.profesional.inactive' | translate}}">
                            <i class="material-icons">visibility_off</i>
                        </span>
                    </div>
                    <div class="professional_email">{{element.professionalDetails?.email}}</div>
                    <div class="professional_document">{{element.professionalDetails?.document}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="totalCost" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.total_cost' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.totalCost}} {{element.currency}} </td>
            </ng-container>

            <ng-container matColumnDef="totalPaid" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.total_paid' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.totalPaid}} {{element.currency}} </td>
            </ng-container>

            <ng-container matColumnDef="operationAddress" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.address_services' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div>{{element.operationAddress?.address}}</div>
                    <div>{{element.operationAddress?.complementAddress}}</div>
                    <div>{{element.operationAddress?.zipCode}}</div>
                    <div *ngIf="element.operationAddress?.city">{{element.operationAddress?.city}}</div>
                    <div *ngIf="element.operationAddress?.province">{{element.operationAddress?.province}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="products">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.products' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let p of element.products ; first as isFirst">
                        <span *ngIf="isFirst">
                            <div><span class="service_name">{{p?.productDescription}}</span></div>
                            <div class="service_price">{{'lm-professional.lm-professional-operations.products.price' | translate}} {{p?.price}} {{p?.currency}}</div>
                            <div class="service_tax">{{p?.taxDetails?.taxRate}} {{p?.taxDetails?.taxType}}</div>
                            <div class="service_date">{{'lm-professional.lm-professional-operations.products.added_on' | translate}} {{p?.createDate}}</div>
                        </span>
                    </div>
                    <div *ngIf="element.products?.length > 1">
                        <button mat-stroked-button (click)="showMore()">
                            <div class="expand_div"><label>{{stringShowMore}}</label>
                                <mat-icon class="mat-18" *ngIf="!buttonShowMore">expand_more</mat-icon>
                                <mat-icon class="mat-18" *ngIf="buttonShowMore">expand_less</mat-icon>
                            </div>
                        </button>
                        <div *ngIf="buttonShowMore">
                            <div *ngFor="let p of element.products | slice:1; let i = index;" class="upperline">
                                <div><span class="service_name">{{p['productDescription']}}</span></div>
                                <div class="service_price">{{'lm-professional.lm-professional-operations.products.price' | translate}} {{p['price']}} {{p['currency']}}</div>
                                <div class="service_tax">{{p['taxDetails']['taxRate']}} {{p['taxDetails']['taxType']}}</div>
                                <div class="service_date">{{'lm-professional.lm-professional-operations.products.added_on' | translate}} {{p['createDate']}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="totalDuration" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.duration' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.totalDuration}} </td>
            </ng-container>

            <ng-container matColumnDef="rejectionMessage" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.rejected_by' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.rejectionMessage}} </td>
            </ng-container>

            <ng-container matColumnDef="unit" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.unit' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
            </ng-container>

            <ng-container matColumnDef="campaigns" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.campaign' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.listOperationCampaigns.length > 0">
                        <div *ngFor="let p of element.listOperationCampaigns">
                            <p>{{p?.campaignCode}}</p>
                        </div>
                    </div>
                    <div *ngIf="element.listOperationCampaigns.length <= 0">
                        <p>{{'lm-professional.lm-professional-operations.campaign.web' | translate}}</p>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="storeName" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.shop' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.storeRest">{{element.storeRest?.name}} ({{element.storeRest?.leroyId}})</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="comercialId" class="spacing">
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.commercial_id' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <div>{{element.commercialId}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="fill">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actions" class="spacing" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>{{'lm-professional.lm-professional-operations.cells.actions' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <!-- <button mat-stroked-button title="Chat"
                        (click)="openDialog(element.chatId, element.professionalDetails, element.clientDetails)">
                        <mat-icon>question_answer</mat-icon>
                    </button> -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getPaginatorData($event)">
        </mat-paginator>
    </div>
</div>