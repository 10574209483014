import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OperationService } from '../services/operation.service';
import { Utilities } from '../../../shared/utilities/utilities';
import * as FileSaver from 'file-saver';
import { ChatsDialogComponent } from '../sections/chats-dialog/chats-dialog.component';
import { ProfessionalModalComponent } from '../sections/professional-dialog/professional-dialog';
import { TransferService } from '../services/transfers.service';
import { CouponVersion, IOperationContent, IOperationData } from '../models/lm-transfers.model';
import { CouponDialogComponent } from '../sections/coupon-dialog/coupon-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['../../utils/mat-table.scss', './operations.component.scss'],
})
export class OperationsComponent implements OnInit, OnDestroy {
  _translationSubscription: Subscription;
  _messages: { [key: string]: string };
  data: IOperationData;
  content: IOperationContent[] = [];
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;
  loading: boolean = false;
  loadExcel: boolean = false;
  filters: any = {};
  buttonShowMore: boolean;
  stringShowMore: string;
  displayedColumns: string[] = [
    'id',
    'operationStatus',
    'operationOfferedServices',
    'type',
    'clientDetails',
    'startTime',
    'endTime',
    'professionalDetails',
    'totalCost',
    'totalPaid',
    'operationAddress',
    'products',
    'campaigns',
    'storeName',
    'comercialId',
    'fill',
    'actions',
  ];

  couponVersion: CouponVersion;
  dialogRef: MatDialogRef<CouponDialogComponent>;

  constructor(
    private _operationService: OperationService,
    private _transferService: TransferService,
    public dialog: MatDialog,
    private _translateService: TranslateService
  ) {
    this.buttonShowMore = false;
    this._translationSubscription = this._translateService
      .get('lm-transfers.operations.logic')
      .subscribe((res: { [key: string]: string }) => {
        this._messages = res;
        this.stringShowMore = this._messages['see_all'];
      });
  }
  ngOnDestroy(): void {
    this._translationSubscription.unsubscribe();
  }

  ngOnInit() { }

  /**
   * Retrieve Operations
   */
  getOperations() {
    this.loading = true;
    this.content = [];
    this.totalElements = 0;
    this._operationService.getOperations(this.page, this.pageSize, this.filters).subscribe({
      next: (response) => {
        this.data = response.data;
        if (this.data.totalElements === 0) {
          alert(this._messages['no_result_found']);
          this.loading = false;
          this.content = [];
        } else {
          this.loading = false;
          this.content = this.data.content;
          this.totalElements = this.data.totalElements;
        }
      },
      error: () => {
        this.loading = false;
        this.content = [];
      }
    });
  }

  getOperationsFilters() {
    this.getOperations();
  }

  getFiltersToOperations(filtersApply) {
    this.filters = filtersApply;
    this.page = 0;
    this.getOperations();
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.getOperations();
  }

  getDateWithTimeZone(d) {
    if (d) {
      const time = new Date(d).getTime();
      const offset = new Date().getTimezoneOffset();
      return new Date(time - offset * 60000).toLocaleString();
    }
    return d;
  }

  isEmpty(str) {
    return !str || str.length === 0;
  }

  showMore() {
    this.buttonShowMore
      ? (this.stringShowMore = this._messages['see_all'])
      : (this.stringShowMore = this._messages['hide_all']);
    this.buttonShowMore = !this.buttonShowMore;
  }

  //Chats
  openDialog(
    chatId: string,
    professional: string,
    clientDetails: string
  ): void {
    if (professional != null) {
      if (clientDetails != null) {
        var dialogRef = this.dialog.open(ChatsDialogComponent, {
          width: '1200px',
          height: 'auto',
          maxHeight: '600px',
          data: {
            chatId: chatId,
            professional: professional['email'],
            client: clientDetails['email'],
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          dialogRef = null;
        });
      } else {
        alert(this._messages['customer_not_found']);
      }
    } else {
      alert(this._messages['professional_not_found']);
    }
  }

  //Professional detail
  openProfessionalDialog(professionalId: number): void {
    //this._router.navigate(['/professionals/'+professionalId]);
    if (professionalId != null) {
      var dialogRef = this.dialog.open(ProfessionalModalComponent, {
        width: '80%',
        height: '80%',
        maxHeight: '80%',
        data: {
          professionalId: professionalId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        dialogRef = null;
      });
    }
  }

  /**
   * Export to excel
   * @param filterstoExcel 
   */
  exportToExcel(filterstoExcel) {
    this.loadExcel = true;
    this._operationService.exportOperationToExcel(filterstoExcel).subscribe({
      next: (res) => {
        const blob = new Blob(
          [
            Utilities.base64toBlob(
              res.excel,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ),
          ],
          {}
        );
        FileSaver.saveAs(
          blob,
          'ReportBackoffice_'.concat(Utilities.getDateActual().concat('.xls'))
        );
        this.loadExcel = false;
      },
      error: () => {
        localStorage.removeItem('token');
      }
    });
  }

  /**
   * Open dialog with coupon information
   * @param couponInfo 
   */
  showCouponVersion(couponInfo): void {
    this._transferService.getCouponVersion(couponInfo['couponVersionId']).subscribe(res => {
      this.couponVersion = res['data'];
      if (this.dialogRef == null) {
        this.dialogRef = this.dialog.open(CouponDialogComponent, {
          width: '400px',
          height: 'auto',
          data: {
            coupon: this.couponVersion
          }
        });

        this.dialogRef.afterClosed().subscribe(result => {
          this.dialogRef = null;
        });
      }
    });
  }

  getSum(list): number {
    let priceOriginal = 0;
    let price = 0;
    for (let i = 0; i < list.length; i++) {
      priceOriginal += list[i]['priceOriginal'];
      price += list[i]['price'];
    }
    return priceOriginal - price;
  }
}
