<div class="operations-title" *ngIf="!hasResponse">
    <h2>
        <p>
            {{'lm-professional.lm-professional-status.modal.title_status_change' | translate}}
        </p>
    </h2>
</div>

<div class="operations-title" *ngIf="hasResponse">
    <h2>
        <p>
            {{'lm-professional.lm-professional-status.modal.title_status_changed' | translate}}
        </p>
    </h2>
</div>

<div class="operations-title" *ngIf="!hasResponse">
    <label style="cursor: default;"
        class="grid-input">{{'lm-professional.lm-professional-status.modal.summary_status_change' | translate}} </label> 
    <label style="cursor: default;" class="grid-texts"> {{statusToChange}} </label>
</div>

<div *ngIf="!hasResponse" class="operations-list">
    <ul>
        <ng-container *ngFor="let item of statusOperations">
            <li [ngClass]="{'not-allowed': !item.allowed}">
                <mat-icon>{{item.allowed ? 'check' : 'close'}}</mat-icon>
                {{item.description}}
            </li>
        </ng-container>
    </ul>
</div>

<div *ngIf="hasResponse" style="padding-left: 20px; padding-right: 20px;">  
    <label style="cursor: default;"
        class="greenText"> {{ msgResponse }} </label> 
</div>
<br>

<hr>

<div class="meter">
    <span style="width:80%;"><span class="progress" [style.display]="inProgress===true ? 'block' : 'none'"></span></span>
  </div>

<div *ngIf="!hasResponse">
    <div class="grid">
        <button type="submit" class="grid__button_back" (click)="dismiss()"
            [disabled]="inProgress">{{'lm-professional.lm-professional-status.modal.buttons.cancel' |
            translate}}</button>
        <button type="submit" class="grid__button" (click)="submit()"
            [disabled]="inProgress">{{'lm-professional.lm-professional-status.modal.buttons.continue' | translate}}</button>
    </div>
</div>

<div *ngIf="hasResponse" class="button_response">
        <button type="button" class="grid__button" style="width: 100%;" (click)="okSubmit()"
            [disabled]="inProgress">{{'lm-professional.lm-professional-status.modal.buttons.ok' | translate}}</button>
</div>